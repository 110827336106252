<template>
  <div class="product-page-container">
    <div class="d-flex flex-wrap justify-content-between key-header mb-2 align-items-start">
      <div class="mb-1">
        <h3>
          Список товаров
        </h3>
        <div style="min-width: 320px">
          В разделе представлен полный список товаров и остатков по ним
        </div>
      </div>
    </div>
    <b-card body-class="p-0">
      <table-nomenclatures/>
    </b-card>
    <b-modal class="detail-products"
             hide-header
             hide-footer
             modal-class="modal-primary"
             content-class="max-width-800"
             centered
             size="xl"
             v-model="show"
    >
      <detail-products
          :productId="currentDetailID"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow, VBModal,
} from 'bootstrap-vue';

import {mapActions, mapState} from "vuex";
import tableLoadProducts from "@/components/product/tableLoadProducts";
import TableNomenclatures from "@/components/product/TableNomenclatures";
import detailProducts from "@/components/product/detailProducts";
import loadProductsFromMp from "@/components/product/LoadProductsFromMp";

export default {
  name: "Products",
  data() {
    return {
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  components: {
    TableNomenclatures,
    BCard,
    BButton,
    BCol,
    BModal,
    BRow,
    BFormInput,
    BFormGroup,
    BFormSelect,
    tableLoadProducts,
    detailProducts,
    loadProductsFromMp,
  },

  directives: {
    'b-modal': VBModal
  },
  methods: {
    ...mapActions('products', ["reloadNomenclaturesAll"]),
    ...mapActions('nomenclatures', ['loadNomenclatures']),
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },
  computed: {
    ...mapState('products', ['listAllCategories', "listAllBrands", "productsWidthWB", "selectedProductsId"]),
    ...mapState('nomenclatures', ['loadProductsProcess']),
  },

  mounted() {
    if (!this.loadProductsProcess) this.loadNomenclatures();
  }
}
</script>

<style scoped>

</style>