<template>
  <div>
    <div v-if="images.length">
      <div class="current-img m-auto d-flex justify-content-center position-relative">
        <div
            v-if="urlSelectedImg === selectedIdentImg"
            @click="selectedIdentImg = ''"
            class="select-btn-ident-img cursor-pointer position-absolute"
        >
          <svg class="mr-1" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.722 7.18414C17.6472 6.88462 17.423 6.73487 17.124 6.65999L12.3403 5.98608L10.1727 1.64311C9.94847 1.11896 9.05153 1.11896 8.82729 1.64311L6.65968 5.9112L1.95074 6.65999C1.65176 6.65999 1.42752 6.88462 1.27803 7.18414C1.20328 7.48365 1.27803 7.78317 1.50227 7.93292L4.94054 11.3025L4.11835 16.0947C4.0436 16.3942 4.19309 16.6937 4.41733 16.8435C4.64156 16.9932 4.94054 17.0681 5.23952 16.9184L9.5 14.672L13.7605 16.9184C13.91 16.9184 13.9847 16.9184 14.1342 16.9184C14.2837 16.9184 14.4332 16.8435 14.5827 16.7686C14.8069 16.6189 14.9564 16.3193 14.8817 16.0198L14.0595 11.2276L17.4977 7.85805C17.722 7.70829 17.7967 7.40877 17.722 7.18414Z"
                fill="black"/>
            <mask id="mask0_1411_9545" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="1" width="17"
                  height="16">
              <path
                  d="M17.722 7.18414C17.6472 6.88462 17.423 6.73487 17.124 6.65999L12.3403 5.98608L10.1727 1.64311C9.94847 1.11896 9.05153 1.11896 8.82729 1.64311L6.65968 5.9112L1.95074 6.65999C1.65176 6.65999 1.42752 6.88462 1.27803 7.18414C1.20328 7.48365 1.27803 7.78317 1.50227 7.93292L4.94054 11.3025L4.11835 16.0947C4.0436 16.3942 4.19309 16.6937 4.41733 16.8435C4.64156 16.9932 4.94054 17.0681 5.23952 16.9184L9.5 14.672L13.7605 16.9184C13.91 16.9184 13.9847 16.9184 14.1342 16.9184C14.2837 16.9184 14.4332 16.8435 14.5827 16.7686C14.8069 16.6189 14.9564 16.3193 14.8817 16.0198L14.0595 11.2276L17.4977 7.85805C17.722 7.70829 17.7967 7.40877 17.722 7.18414Z"
                  fill="white"/>
            </mask>
            <g mask="url(#mask0_1411_9545)">
              <rect x="0.5" y="0.5" width="18" height="18" fill="#7367F0"/>
            </g>
          </svg>
          <span style="color: #7367F0">
          для приемки
        </span>
        </div>
        <div
            v-else
            @click="selectedIdentImg = urlSelectedImg"
            class="select-btn-ident-img cursor-pointer position-absolute"
        >
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 6.5L10 1.5L7 6.5L1.5 8L5.5 12L5 17.5L10 15.5L15.5 17L14.5 11.5L18 7.5L13 6.5Z" stroke="#7367F0"/>
          </svg>
          <span style="color: #7367F0">
          выбрать для приемки
        </span>
        </div>
        <b-img :src="urlSelectedImg"/>
      </div>
      <b-carousel
          class="mt-2"
          id="carousel-1"
          controls
          :interval="0"
          background="#fff"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
            v-for="(image, i) in images"
            :key="i"
        >
          <template #img>
            <div class="d-flex justify-content-between" style="margin: auto; width: 110px">
              <b-img style="width: 50px;"
                     :style="urlSelectedImg === image ? 'border: 3px solid #7367f0' : 'border: none'"
                     class="cursor-pointer"
                     @click="urlSelectedImg = image"
                     :src="image"
              ></b-img>
              <b-img
                  v-if="images[i+1]"
                  style="width: 50px;"
                  class="cursor-pointer"
                  :style="urlSelectedImg === images[i+1] ? 'border: 3px solid #7367f0' : 'border: none'"
                  @click="urlSelectedImg = images[i+1]"
                  :src="images[i+1]"
              ></b-img>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="load-new-image mt-2">
      <b-form-file
          v-model="newFile"
          :state="Boolean(newFile)"
          browse-text=""
          drop-placeholder="Drop file here..."
      >
        <template slot="placeholder">
          <div class="d-flex flex-wrap align-items-center justify-content-center">
            <svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="27" cy="26.5" r="26.5" fill="#7367F0" fill-opacity="0.12"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.483 32.8295C26.3538 32.8295 26.2247 32.7003 26.0955 32.5712L20.9288 27.4045C20.4122 26.8878 20.4122 26.1128 20.9288 25.5962C21.4455 25.0795 22.2205 25.0795 22.7372 25.5962L25.708 28.567V13.5837C25.708 12.8087 26.2247 12.292 26.9997 12.292C27.7747 12.292 28.2913 12.8087 28.2913 13.5837V28.567L31.2622 25.5962C31.7788 25.0795 32.5538 25.0795 33.0705 25.5962C33.5872 26.1128 33.5872 26.8878 33.0705 27.4045L27.9038 32.5712C27.8393 32.6357 27.7747 32.668 27.7101 32.7003C27.6455 32.7326 27.5809 32.7649 27.5163 32.8295C27.3872 32.9587 27.1288 32.9587 26.9997 32.9587C26.8705 32.9587 26.6122 32.9587 26.483 32.8295ZM39.9163 36.8337V32.9587C39.9163 32.1837 39.3997 31.667 38.6247 31.667C37.8497 31.667 37.333 32.1837 37.333 32.9587V36.8337C37.333 37.6087 36.8163 38.1253 36.0413 38.1253H17.958C17.183 38.1253 16.6663 37.6087 16.6663 36.8337V32.9587C16.6663 32.1837 16.1497 31.667 15.3747 31.667C14.5997 31.667 14.083 32.1837 14.083 32.9587V36.8337C14.083 39.0295 15.7622 40.7087 17.958 40.7087H36.0413C38.2372 40.7087 39.9163 39.0295 39.9163 36.8337Z" fill="#7367F0" fill-opacity="0.7"/>
              <mask id="mask0_1401_2541" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="14" y="12" width="26" height="29">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.483 32.8295C26.3538 32.8295 26.2247 32.7003 26.0955 32.5712L20.9288 27.4045C20.4122 26.8878 20.4122 26.1128 20.9288 25.5962C21.4455 25.0795 22.2205 25.0795 22.7372 25.5962L25.708 28.567V13.5837C25.708 12.8087 26.2247 12.292 26.9997 12.292C27.7747 12.292 28.2913 12.8087 28.2913 13.5837V28.567L31.2622 25.5962C31.7788 25.0795 32.5538 25.0795 33.0705 25.5962C33.5872 26.1128 33.5872 26.8878 33.0705 27.4045L27.9038 32.5712C27.8393 32.6357 27.7747 32.668 27.7101 32.7003C27.6455 32.7326 27.5809 32.7649 27.5163 32.8295C27.3872 32.9587 27.1288 32.9587 26.9997 32.9587C26.8705 32.9587 26.6122 32.9587 26.483 32.8295ZM39.9163 36.8337V32.9587C39.9163 32.1837 39.3997 31.667 38.6247 31.667C37.8497 31.667 37.333 32.1837 37.333 32.9587V36.8337C37.333 37.6087 36.8163 38.1253 36.0413 38.1253H17.958C17.183 38.1253 16.6663 37.6087 16.6663 36.8337V32.9587C16.6663 32.1837 16.1497 31.667 15.3747 31.667C14.5997 31.667 14.083 32.1837 14.083 32.9587V36.8337C14.083 39.0295 15.7622 40.7087 17.958 40.7087H36.0413C38.2372 40.7087 39.9163 39.0295 39.9163 36.8337Z" fill="white"/>
              </mask>
              <g mask="url(#mask0_1401_2541)">
              </g>
            </svg>
            <span class="mt-1 text-wrap text-center">Выберите или перетащите сюда фото для идентификации товара на приемке</span>
            <div style="font-size: 10px; color: #B9B9C3" >Формат фото .jpeg, .png, до 5МБ </div>
          </div>
        </template>
      </b-form-file>
    </div>
  </div>
</template>

<script>
import {BCarousel, BCarouselSlide, BImg, BFormFile} from 'bootstrap-vue'

export default {
  data() {
    return {
      urlSelectedImg: this.images[0],
      selectedIdentImg: this.identImg || '',
      newFile: null,
    }
  },
  props: {
    identImg: String,
    images: {
      type: Array, default() {
        return []
      }
    }
  },
  components: {
    BCarousel, BCarouselSlide, BImg, BFormFile
  },
  computed: {
    getLength() {
      if (this.images.length < 3) return 2
      return this.images.length / 2
    }
  },
  methods: {
    onSlideStart(slide) {

    },
    onSlideEnd(slide) {

    }
  }
}
</script>

<style scoped>
.current-img {
  height: 180px;
}

.select-btn-ident-img {
  top: 10px;
  left: -7px;
  background-color: white;
  border-radius: 5px;
  padding: 2px 5px;
  border: 1px solid #7367F0;
}

.load-new-image {
  min-height: 150px;
}
</style>