<template>
  <b-col class="detail-products-container">
    <b-row>
      <h3 class="text-center w-100">Добавить фото товара</h3>
      <div class="mb-1 text-center w-100">Подробная информация о вашем товаре</div>
    </b-row>
    <b-row>
      <h4>{{ getProduct.name }}</h4>
    </b-row>
    <b-row class="product-name">
      <b-col>
        <b-row>
          <b-col class="p-0 mt-2 mr-2">
            <select-ident-img
                :images="getProduct.img"
                :identImg="getProduct.identImg"
            />
          </b-col>
          <b-col>
            <b-row class="media-wrap-col border-top pt-1 mb-1 flex-nowrap">
              <b-col class="no-margin-r mb-1 mr-4">
                <b-row class="flex-nowrap justify-content-between mb-2">
                  <span class="font-weight-bolder mr-3">Категория: </span> <span class="text-nowrap">{{
                    getProduct.object
                  }}</span>
                </b-row>
                <b-row class="flex-nowrap justify-content-between">
                  <span class="font-weight-bolder mr-3">Бренд: </span> <span class="text-nowrap">{{
                    getProduct.brand
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="flex-nowrap justify-content-between mb-2">
                  <span class="font-weight-bolder mr-3">Артикул: </span> <span class="text-nowrap">{{
                    getProduct.article
                  }}</span>
                </b-row>
                <b-row class="flex-nowrap justify-content-between">
                  <span class="font-weight-bolder mr-3">Цвет: </span> <span
                    class="text-nowrap">{{ getProduct.characteristics.find(ch => !!ch.color).color }}</span>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2"><h3>Габариты</h3></b-row>
            <b-row class="media-wrap-col border-top pt-1 flex-nowrap">
              <b-col class="no-margin-r mb-1 mr-4">
                <b-row class="flex-nowrap justify-content-between mb-2">
                  <span class="font-weight-bolder mr-3 text-nowrap">Длина упаковки(см):</span> <span class="text-nowrap">{{
                    getProduct.parameters.length
                  }}</span>
                </b-row>
                <b-row class="flex-nowrap justify-content-between mb-2">
                  <span class="font-weight-bolder mr-3 text-nowrap">Ширина упаковки(см):</span> <span
                    class="text-nowrap">{{
                    getProduct.parameters.width
                  }}</span>
                </b-row>
                <b-row class="flex-nowrap justify-content-between">
                  <span class="font-weight-bolder mr-3 text-nowrap">Высота упаковки(см):</span> <span
                    class="text-nowrap">{{
                    getProduct.parameters.height
                  }}</span>
                </b-row>
              </b-col>
              <b-col>
                <b-row class="flex-nowrap justify-content-between mb-2">
                  <span class="font-weight-bolder mr-3">Вес (гр): </span> <span class="text-nowrap">{{
                    getProduct.parameters.weight
                  }}</span>
                </b-row>
                <b-row class="flex-nowrap justify-content-between">
                  <span class="font-weight-bolder mr-3 text-nowrap">Вес в упаковке (гр): </span> <span
                    class="text-nowrap">{{
                    getProduct.parameters.weightWidthPack
                  }}</span>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="pt-1 flex-nowrap">
              <b-col class="p-0">
                <b-table
                    :items="getBarcodes"
                    :fields="fieldsBarcodes"
                ></b-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapState} from 'vuex'
import {BRow, BCol, BTable} from 'bootstrap-vue'
import selectIdentImg from "@/components/product/selectIdentImg";


export default {
  name: "detailProducts",
  components: {
    BRow,
    BCol,
    BTable,
    selectIdentImg
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      fieldsBarcodes: [
        {key: 'size', label: 'размер'},
        {key: 'barcode', label: 'штрихкод'},
      ],
      swiperData: [
        {icon: 'GithubIcon', text: 'Getting Started'},
        {icon: 'FacebookIcon', text: 'Pricing & Plans'},
        {icon: 'TwitterIcon', text: 'Sales Question'},
        {icon: 'InstagramIcon', text: 'Usage Guides'},
        {icon: 'GitlabIcon', text: 'General Guide'},
      ],
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  props: {
    productId: Number
  },
  methods: {},
  computed: {
    getBarcodes() {
      if (this.getProduct.barcode) return [{size: 'без размера', barcode: this.getProduct.barcode}]
      let res = [];
      if (Array.isArray(this.getProduct.stockff)) res = res.concat(this.getProduct.stockff);
      if (Array.isArray(this.getProduct.stockmp)) res = res.concat(this.getProduct.stockmp);
      return res
    },
    getProduct() {
      return this.productsWidthWB.find(prod => prod.id === this.productId);
    },
    ...mapState('products', ["productsWidthWB"])
  }
}

</script>

<style>
.detail-products-container {

}

@media (max-width: 995px) {

  .media-wrap-col {
    flex-wrap: wrap !important;
  }
  .no-margin-r {
    margin-right: 0 !important;
  }
}


</style>